<template>
  <Loading v-if="!isReady"/>
  <div v-else-if="availablesBuildings.length === 0">
    <img :src="$t('image_urls.error_building')" alt="">
    <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8 mr-2" @click="previousStep">
      <i class="fas fa-arrow-left"></i>
    </button>
  </div>
  <div v-else>
    <img :src="$t('image_urls.building_select')" alt="">
    <div class="relative inline-flex">
      <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/></svg>
      <select class="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none" v-model="selectedBuilding">
        <option value="0">{{ $t('choose_building')}}</option>
        <option v-for="building in availablesBuildings" :value="building.id" :key="building">{{ building.name }}</option>
      </select>

    </div>
    <div>
      <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8 mr-2" @click="previousStep">
        <i class="fas fa-arrow-left"></i>
      </button>
      <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8" @click="nextStep" :disabled="selectedBuilding == 0">
        {{ $t('next') }}
      </button>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading";

export default {
  name: "BuildingSelection",
  components: {Loading},
  data() {
    return {
      isReady: false
    }
  },
  computed: {
    selectedBuilding: {
      get() {
        return this.$store.state.selectedBuildingId;
      },
      set(value) {
        this.$store.commit('updateSelectedBuildingId', value);
      }
    },
    availablesBuildings: {
      get() {
        return this.$store.state.availablesBuildings;
      },
      set(value) {
        this.$store.commit('updateAvailablesBuildings', value);
      }
    }
  },
  mounted() {
    this.$apiComeen.getSpaceBuildings().then(result => {
      this.availablesBuildings = result.data;
      this.isReady = true;
    });
  },
  methods: {
    previousStep() {
      this.$emit('go-to-page', 'GuestSelection');
    },
    nextStep() {
      this.$emit('go-to-page','KioskSelection');
    }
  }
}
</script>

<style scoped>

</style>