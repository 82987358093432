<template>
  <div>
    <img :src="$t('image_urls.welcome')" alt="">
    <p class="mt-6 mx-2">{{ $t('introduction')}}</p>
    <a class="text-blue-500 underline" href="https://help.comeen.io/en/articles/4290475-welcome" target="_blank">{{ $t('more_information') }}</a>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-8" @click="nextStep">
      <i class="far fa-hand-paper animated faa-shake faa-slow"></i> {{ $t('welcome') }}
    </button>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    nextStep() {
      this.$emit('go-to-page','GuestSelection');
    }
  }
}
</script>

<style scoped>

</style>