<template>
  <div>{{ $t('loading') }}...</div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>

</style>