<template>
  <Loading v-if="!isReady"/>
  <div v-else-if="availablesKiosks.length === 0">
    <img :src="$t('image_urls.error_kiosk')" alt="">
    <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8 mr-2" @click="previousStep">
      <i class="fas fa-arrow-left"></i>
    </button>
  </div>
  <div v-else>
    <img :src="$t('image_urls.kiosk_select')" alt="">
    <div class="relative inline-flex my-6">
      <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#648299" fill-rule="nonzero"/></svg>
      <select class="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none" v-model="selectedKiosk">
        <option value="0">{{ $t('choose_kiosk')}}</option>
        <option v-for="kiosk in availablesKiosks" :value="kiosk.id" :key="kiosk">{{ kiosk.name }}</option>
      </select>
    </div>
    <div>
      <label class="inline-flex items-center mt-3" >
        <input type="checkbox" class="form-checkbox h-5 w-5 text-blue-600" v-model="sendInstructionsToVisitors"><span class="ml-2 text-gray-700 text-xs">{{ $t('send_instructions') }}</span>
      </label>
    </div>
    <div v-if="nameError" class="text-red-500">You need to set a name for your event in order to manage it with Comeen Workplace.</div>
    <div>
      <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8 mr-2" @click="previousStep">
        <i class="fas fa-arrow-left"></i>
      </button>
      <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8" @click="nextStep" :disabled="selectedKiosk == 0">
        {{ $t('next') }}
      </button>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading";
export default {
  name: "KioskSelection",
  components: {Loading},
  data() {
    return {
      isReady: false,
      sendInstructionsToVisitors: false,
      nameError: false,
    }
  },
  async mounted() {
    this.$apiComeen.getBuildingLobbies(this.selectedBuilding).then(result => {
      this.availablesKiosks = result.data;
      this.isReady = true;
    })
  },
  computed: {
    selectedBuilding: {
      get() {
        return this.$store.state.selectedBuildingId;
      },
      set(value) {
        this.$store.commit('updateSelectedBuildingId', value);
      }
    },
    selectedKiosk: {
      get() {
        return this.$store.state.selectedKioskId;
      },
      set(value) {
        this.$store.commit('updateSelectedKioskId', value);
      }
    },
    availablesKiosks: {
      get() {
        return this.$store.state.availablesKiosks;
      },
      set(value) {
        this.$store.commit('updateAvailablesKiosks', value);
      }
    }
  },
  methods: {
    previousStep() {
      this.$emit('go-to-page', 'BuildingSelection');
    },
    async nextStep() {
      let subject = await this.getItemSubject();
      if(subject.length == 0) {
        this.nameError = true;
        return;
      }
      let guests = this.$store.state.guests.filter(guest => guest.isInvited);
      let itemId;

      try {
        itemId = await this.getItemId();
      } catch(e) {
        itemId = await this.saveItem();
      }

      let startDate = await this.getStartDate();
      let endDate = await this.getEndDate();

      let comeenAttendeesIds = [];
      let space_uuid = this.$apiComeen.userProperties.space.uuid;
      let organizer_id = this.$apiComeen.userProperties.id;

      let organizer_email = (await this.getItemOrganizer()).emailAddress
      try {
        let organizer = await this.$apiComeen.getAttendeeByEmail(organizer_email);
        organizer_id = organizer.data.id;
      } catch(e) {
        console.log("Organizer not in Comeen");
      }

      for(let guest of guests) {
        try {
          let attendee = await this.$apiComeen.getAttendeeByEmail(guest.email);
          comeenAttendeesIds.push(attendee.data.id);
        } catch (e) {
          let newAttendee = await this.$apiComeen.postAttendee(guest);
          comeenAttendeesIds.push(newAttendee.data.id);
        }
      }

      let appointmentDetails = {
        topic: subject,
        start_datetime: startDate,
        end_datetime: endDate,
        provider_id: itemId,
        organizer_id: organizer_id,
        attendee_ids: comeenAttendeesIds,
        kiosk_id: this.selectedKiosk
      }

      let result = await this.$apiComeen.postAppointmentDetail(space_uuid, appointmentDetails);
      this.$store.commit('setAppointmentId', result.data.id);
      if(this.sendInstructionsToVisitors) {
        let attendeesEmails = guests.map(guest => guest.email);
        let kioskUuid = this.$store.getters.getSelectedKioskObj.uuid;
        this.$apiComeen.postIntructionsToGuests(attendeesEmails, kioskUuid);
      }

      this.$emit('go-to-page','EventManaged');
    },
    async getStartDate() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.start.getAsync) {
          resolve(window.Office.context.mailbox.item.start)
        } else {
          window.Office.context.mailbox.item.start.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getEndDate() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.end.getAsync) {
          resolve(window.Office.context.mailbox.item.end)
        } else {
          window.Office.context.mailbox.item.end.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getItemId() {
      return new Promise((resolve, reject) => {
        if (window.Office.context.mailbox.item.itemId) {
          resolve(window.Office.context.mailbox.item.itemId)
        } else {
          window.Office.context.mailbox.item.getItemIdAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getItemSubject() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.subject.getAsync) {
          resolve(window.Office.context.mailbox.item.subject)
        } else {
          window.Office.context.mailbox.item.subject.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getItemOrganizer() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.organizer.getAsync) {
          resolve(window.Office.context.mailbox.item.organizer)
        } else {
          window.Office.context.mailbox.item.organizer.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async saveItem() {
      return new Promise((resolve, reject) => {
        window.Office.context.mailbox.item.saveAsync(asyncResult => {
          if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
            reject(asyncResult.error.message);
          }
          else {
            resolve(asyncResult.value);
          }
        })
      })
    },

  }
}
</script>

<style scoped>

</style>