<template>
  <div v-if="isReady" class="px-4">
    <img :src="$t('image_urls.event_managed')" alt="">
    <div class="text-left ml-4 my-6">
      <h2 class="text-2xl text-blue-500">{{ $t('building') }}</h2>
      <div class="ml-2">{{ building.name }}</div>
      <h2 class="text-2xl text-blue-500 mt-2">{{ $t('kiosk') }}</h2>
      <div class="ml-2">{{ kiosk.name }}</div>
    </div>
    <button class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-8" @click="updateAppointment">
      {{ $t('update_comeen') }}
    </button>
    <button class="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded my-8" @click="deleteAppointment">
      {{ $t('disable_comeen') }}
    </button>
  </div>
  <Loading v-else/>
</template>

<script>
import Loading from "./Loading";
export default {
  name: "EventManaged",
  components: {Loading},
  data() {
    return {
      isReady: true
    }
  },
  computed: {
    building() {
      return this.$store.getters.getSelectedBuildingObj;
    },
    kiosk() {
      return this.$store.getters.getSelectedKioskObj;
    },
    appointmentId() {
      return this.$store.getters.getAppointmentId;
    }
  },
  methods: {
    async deleteAppointment() {
      this.isReady = false;
      await this.$apiComeen.deleteAppointment(this.appointmentId);
      this.$store.commit('resetEverything');
      this.$emit('go-to-page', 'Welcome');
    },
    async updateAppointment() {
      this.isReady = false;
      let subject = await this.getItemSubject();
      if(subject.length == 0) {
        this.nameError = true;
        return;
      }

      let startDate = await this.getStartDate();
      let endDate = await this.getEndDate();

      let organizer_id = this.$apiComeen.userProperties.id;
      let organizer_email = (await this.getItemOrganizer()).emailAddress
      try {
        let organizer = await this.$apiComeen.getAttendeeByEmail(organizer_email);
        organizer_id = organizer.data.id;
      } catch(e) {
        console.log("Organizer not in Comeen");
      }

      let appointmentDetails = {
        topic: subject,
        organizer_id: organizer_id,
        start_datetime: startDate,
        end_datetime: endDate,
        kiosk_id: this.selectedKiosk
      }
      await this.$apiComeen.updateAppointmentDetails(this.appointmentId, appointmentDetails);
      this.isReady = true;
    },

    async getItemSubject() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.subject.getAsync) {
          resolve(window.Office.context.mailbox.item.subject)
        } else {
          window.Office.context.mailbox.item.subject.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getStartDate() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.start.getAsync) {
          resolve(window.Office.context.mailbox.item.start)
        } else {
          window.Office.context.mailbox.item.start.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getEndDate() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.end.getAsync) {
          resolve(window.Office.context.mailbox.item.end)
        } else {
          window.Office.context.mailbox.item.end.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
    async getItemOrganizer() {
      return new Promise((resolve, reject) => {
        if (!window.Office.context.mailbox.item.organizer.getAsync) {
          resolve(window.Office.context.mailbox.item.organizer)
        } else {
          window.Office.context.mailbox.item.organizer.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>