<template>
  <div>{{ $t('error_version') }}...</div>
</template>

<script>
export default {
  name: "ErrorVersion"
}
</script>

<style scoped>

</style>