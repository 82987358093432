import Vuex from 'vuex';
import Vue from "vue";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        selectedBuildingId: 0,
        selectedKioskId: 0,
        availablesBuildings: [],
        availablesKiosks: [],
        guests: [],
        appointmentId: null,
        alreadyManagedAppointment: null
    },
    mutations: {
        updateSelectedBuildingId(state, newValue) {
            state.selectedBuildingId = newValue;
        },
        updateSelectedKioskId(state, newValue) {
            state.selectedKioskId = newValue;
        },
        updateAvailablesBuildings(state, payload) {
            state.availablesBuildings = payload;
        },
        updateAvailablesKiosks(state, payload) {
            state.availablesKiosks = payload;
        },
        updateGuests(state, payload) {
            state.guests = payload;
        },
        syncGuests(state, payload) {
            let guests = [];
            for(let newGuest of payload) {
                let alreadyInArrayGuest = state.guests.find(oldGuest => oldGuest.email === newGuest.email);
                if(alreadyInArrayGuest) {
                    guests.push(alreadyInArrayGuest);
                } else {
                    guests.push(newGuest);
                }
            }
            state.guests = guests;
        },
        setAlreadyManagedAppointment(state, payload) {
            state.alreadyManagedAppointment = payload;
        },
        setAppointmentId(state, payload) {
            state.appointmentId = payload;
        },
        resetEverything(state) {
            state.selectedBuildingId = 0;
            state.selectedKioskId = 0;
            state.appointmentId = null;
            state.alreadyManagedAppointment = null;
        }
    },
    getters: {
        getSelectedBuildingObj: (state) => {
            if(state.alreadyManagedAppointment) {
                return state.alreadyManagedAppointment.building;
            }
            return state.availablesBuildings.find(building => building.id == state.selectedBuildingId);
        },
        getSelectedKioskObj: (state) => {
            if(state.alreadyManagedAppointment) {
                return state.alreadyManagedAppointment.kiosk;
            }
            return state.availablesKiosks.find(kiosk => kiosk.id == state.selectedKioskId);
        },
        getAppointmentId: (state) => {
            if(state.alreadyManagedAppointment) {
                return state.alreadyManagedAppointment.id;
            }
            return state.appointmentId;
        }
    }
});