<template>
  <div id="app">
    <component :is="page" @go-to-page="goToPage"/>
  </div>
</template>

<script>

import Loading from "./views/Loading";
import Welcome from "./views/Welcome";
import NoAccount from "./views/NoAccount";
import GuestSelection from "./views/GuestSelection";
import BuildingSelection from "./views/BuildingSelection";
import KioskSelection from "./views/KioskSelection";
import EventManaged from "./views/EventManaged";
import ErrorCalendarNotOwned from "./views/ErrorCalendarNotOwned";
import ErrorVersion from "./views/ErrorVersion";

export default {
  name: 'App',
  components: {Loading, Welcome, NoAccount, GuestSelection, BuildingSelection, KioskSelection, EventManaged, ErrorCalendarNotOwned, ErrorVersion},
  data() {
    return {
      page: 'Loading'
    }
  },
  async mounted() {
    if (!window.Office.context.requirements.isSetSupported('IdentityAPI', '1.3') || !window.Office.context.requirements.isSetSupported('Mailbox', '1.1')) {
      this.page = 'ErrorVersion'
    } else {
      window.OfficeRuntime.auth.getAccessToken({allowSignInPrompt: true}).then(async microsoft_token => {
        let result = await this.$apiComeen.getComeenTokenWithMicrosofToken(microsoft_token)
        this.$apiComeen.setTokenHeader(result.data);
        this.$apiComeen.getMe().then(async (result) => {
          this.$apiComeen.setUserProperties(result.data);
          try {
            let itemId = await this.getItemId();
            let comeenAppointment = await this.$apiComeen.getAppointmentByProviderId(itemId);
            this.$store.commit('setAlreadyManagedAppointment', comeenAppointment.data);
            this.page = "EventManaged";
          } catch(e) { // At this point etheir itemId does not exist (new item) or comeen does not manage the appointment yet
            this.$apiComeen.setUserProperties(result.data);
            this.page = "Welcome";
          }
        });
      }).catch(err => {
        console.error("error", err)
        this.page = "NoAccount";
    })
    }
  },
  methods: {
    goToPage(pageName) {
      this.page = pageName;
    },
    async getItemId() {
      return new Promise((resolve, reject) => {
        if (window.Office.context.mailbox.item.itemId) {
          resolve(window.Office.context.mailbox.item.itemId)
        } else {
          window.Office.context.mailbox.item.getItemIdAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    },
  },
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
