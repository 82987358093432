<template>
  <div>
    <img :src="$t('image_urls.welcome')" alt="">
    <p class="my-6 mx-2" v-html="$t('no_account')"></p>
    <a class="text-blue-500 underline" href="https://comeen.com/products/workplace/visitors-management" target="_blank">{{ $t('more_information') }}</a>
  </div>
</template>

<script>
export default {
  name: "NoAccount",
}
</script>

<style scoped>

</style>