<template>
<div>
  <img src="https://getcomeen.com/storage/app/media/Google-Calendar-Add-on/error-calendar.png" alt="">
  <div>{{ $('cannot_use_not_calendar') }}</div>
</div>
</template>

<script>
export default {
  name: "ErrorCalendarNotOwned"
}
</script>

<style scoped>

</style>