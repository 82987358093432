import axios from "axios";
export class ComeenApi {

    constructor() {
        this.axiosConfig = {
            baseURL: process.env.VUE_APP_COMEEN_API_URL,
            headers: {}
        }
        this.commen_client_id = process.env.VUE_APP_COMEEN_CLIENT_ID;
        this.scope = 'public profile spaces:read buildings:read kiosks:read people:read guests:write appointments:read appointments:write'

    }

    getComeenTokenWithMicrosofToken(microsoft_token) {
        return axios.post('/oauth/token',
            {
                grant_type: 'assertion',
                provider: 'microsoft',
                client_id: this.commen_client_id,
                assertion: microsoft_token,
                scope: this.scope
            },
            this.axiosConfig);
    }

    setTokenHeader(data) {
        this.axiosConfig.headers.Authorization = `${data.token_type} ${data.access_token}`;
    }

    setUserProperties(data) {
        this.userProperties = data;
    }

    getMe() {
        return axios.get(`/api/v1/me.json`, this.axiosConfig);
    }

    getSpaceBuildings() {
        const endpoint = '/api/v1/spaces/' + this.userProperties.space.uuid + '/buildings.json';
        return axios.get(endpoint, this.axiosConfig);
    }

    getBuildingLobbies(building) {
        const endpoint = '/api/v1/buildings/' + building + '/kiosks.json';
        return axios.get(endpoint, this.axiosConfig);
    }

    getAppointmentByProviderId(id) {
        const endpoint = '/api/v1/spaces/' + this.userProperties.space.uuid + '/appointments/find_by_provider_id.json?id=' + encodeURIComponent(id);
        return axios.get(endpoint, this.axiosConfig);
    }

    getAttendeeByEmail(email) {
        const queryParams = new URLSearchParams({email}).toString();
        const endpoint = '/api/v1/people/find_by_email.json?' + queryParams;
        return axios.get(endpoint,this.axiosConfig)
    }

    postAttendee(guest) {
        const endpoint = '/api/v1/guests.json';
        return axios.post(endpoint, {
            guest: {
                email: guest.email,
                first_name: guest.first_name,
                last_name: ''
            }
        }, this.axiosConfig)
    }

    postAppointmentDetail(space_uuid, appointment) {
        const endpoint = '/api/v1/spaces/' + space_uuid + '/appointments.json';
        return axios.post(endpoint,{appointment}, this.axiosConfig);
    }

    postIntructionsToGuests(attendees, kiosk_uuid) {
        const endpoint = '/api/v1/appointments/send_instructions.json?lobby_uuid=' + kiosk_uuid;
        return axios.post(endpoint, {attendees}, this.axiosConfig);
    }

    deleteAppointment(id) {
        const endpoint = '/api/v1/appointments/' + id + '.json';
        return axios.delete(endpoint, this.axiosConfig);
    }

    updateAppointmentDetails(id, appointment) {
        const endpoint = '/api/v1/appointments/' + id + '.json';
        return axios.patch(endpoint, { appointment }, this.axiosConfig);
    }
}