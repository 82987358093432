import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './assets/index.css'
import {store} from "@/store";
import {ComeenApi} from "@/api/comeen";
import VueI18n from "vue-i18n";

Vue.config.productionTip = false
Vue.prototype.$apiComeen = new ComeenApi();

Vue.use(VueI18n)

window.Office.initialize = () => {
  const i18n = new VueI18n({
    locale: window.Office.context.displayLanguage.startsWith('fr') ? 'fr' : 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
  })

  new Vue({
    render: h => h(App),
    i18n,
    store
  }).$mount('#app')
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

