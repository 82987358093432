<template>
  <div v-if="guests.length > 0">
    <img :src="$t('image_urls.visitors_select')" alt="">
    <div class="text-left ml-4">
      <div v-for="guest in guests" :key="guest.email">
        <label class="inline-flex items-center mt-3">
          <input type="checkbox" class="form-checkbox h-5 w-5 text-blue-600" v-model="guest.isInvited"><span class="ml-2 text-gray-700">{{ guest.email }}</span>
        </label>
      </div>
    </div>
    <p v-if="needVisitor" class="text-red-500">{{ $t('need_visitors') }}</p>
    <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8 mr-2" @click="syncGuests" title="Sync Guests">
      <i class="fas fa-sync"></i>
    </button>
    <button class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8" @click="nextStep">
      {{ $t('next') }}
    </button>
  </div>
  <div v-else>
    <img :src="$t('image_urls.visitors_select')" alt="">

    <p class="font-bold px-4">{{ $t('need_visitors') }}</p>

    <button v-if="!readOnly" class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded my-8 mr-2" @click="syncGuests" title="Sync Guests">
      {{ $t('refresh_guest_list') }}
      <i class="fas fa-sync pl-4"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: "GuestSelection",
  data() {
    return {
      needVisitor: false,
      readOnly: false,
    }
  },
  methods: {
    nextStep() {
      if(this.guests.find(guest => guest.isInvited)) {
        this.$emit('go-to-page','BuildingSelection');
      } else {
        this.needVisitor = true;
      }
    },
    async syncGuests() {
      let requiredPeople = await this.getAttendees(window.Office.context.mailbox.item.requiredAttendees);
      let optionalPeople = await this.getAttendees(window.Office.context.mailbox.item.optionalAttendees);

      let allAttendees = requiredPeople.concat(optionalPeople).filter(attendee => attendee.emailAddress !== this.$apiComeen.userProperties.email);
      let guests = allAttendees.map(attendee => {
        return {
          email: attendee.emailAddress,
          isInvited: attendee.recipientType === 'externalUser' || attendee.recipientType === 'other',
          first_name: attendee.displayName
        }
      });
      this.$store.commit('syncGuests', guests);
    },
    async getAttendees(attendee_obj) {
      return new Promise((resolve, reject) => {
        if(Array.isArray(attendee_obj)) {
          console.log(attendee_obj);
          resolve(attendee_obj)
        } else {
          attendee_obj.getAsync(asyncResult => {
            if (asyncResult.status == window.Office.AsyncResultStatus.Failed){
              reject(asyncResult.error.message);
            }
            else {
              resolve(asyncResult.value);
            }
          })
        }
      })
    }
  },
  computed: {
    guests: {
      get() {
        return this.$store.state.guests;
      },
      set(value) {
        this.$store.commit('updateGuests', value);
      }
    }
  },
  mounted() {
    this.syncGuests();

    this.readOnly = Array.isArray(window.Office.context.mailbox.item.requiredAttendees)
  }
}
</script>

<style scoped>

</style>